import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Icon from './Icon';
import { initIcons } from '../utils/icons';

initIcons();

const InterviewFlow = () => {
  const { allInterviewFlowJson } = useStaticQuery(graphql`
    query InterviewFlowQuery {
      allInterviewFlowJson {
        edges {
          node {
            id
            icon
            title
            text
          }
        }
      }
    }
  `);
  const { edges: steps } = allInterviewFlowJson;

  return (
    <div className="container">
      <div className="row interview-flow">
        {steps &&
          steps.map(({ node }, index) => (
            <div className="col col-12 col-md-6 interview-step" key={node.id}>
              <div className="row">
                <div className="col">
                  <div className="row">
                    <div className="col">
                      <div className="row justify-content-center">
                        <Icon
                          iconName={node.icon}
                          className="interview-step-icon"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col interview-step-header">
                      <h3>{node.title}</h3>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p>{node.text}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default InterviewFlow;
